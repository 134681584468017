import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Details from './CatalogueProductDetails';

class Main extends Component {
    render() {
        return (
            <Switch>
                {/* <Route
                            path={`${this.props.match.path}/:productId/details`}
                            component={CatalogueCategoriesList}
                        /> */}
                <Route
                    path={`${this.props.match.path}/:productId/details`}
                    component={Details}
                    key={this.props.location.pathname}
                />
                <Route path={`${this.props.match.path}`} component={List} />
            </Switch>
        );
    }
}

export default Main;
