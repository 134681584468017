import React from 'react';
import { FieldBase } from './FieldBase';
import FieldWrapper from './FieldWrapper';

import './SelectField.scss';

export default class SelectField extends FieldBase {
    render() {
        return (
            <FieldWrapper {...this.props}>
                <div className='select-field__wrapper'>
                    <select
                        className='field select-field'
                        value={this.props.value}
                        onChange={event => this.onInput(event.target.value)}
                    >
                        {this.props.options.length > 0 ? (
                            this.props.options.map(o => {
                                if (o.options) {
                                    return (
                                        <optgroup key={o.value} label={o.label}>
                                            {o.options.map(oo => (
                                                <option key={oo.value} value={oo.value}>
                                                    {oo.label}
                                                </option>
                                            ))}
                                        </optgroup>
                                    );
                                }

                                return (
                                    <option key={o.value} value={o.value}>
                                        {o.label}
                                    </option>
                                );
                            })
                        ) : (
                            <option disabled value={undefined}>
                                no options to select
                            </option>
                        )}
                    </select>
                </div>
            </FieldWrapper>
        );
    }
}
