import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import './Nav.scss';

// import $ from 'jquery';

import appTranslate from '../../appTranslate.json';

import logoImg from '../../assets/logo/logo_ge.png';
import { AppContext } from '../../services/context';

class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: false
        };
    }

    toggleNav = () => {
        this.setState({ navOpen: !this.state.navOpen });
    };

    closeNav = () => {
        this.setState({ navOpen: false });
    };

    logout = () => {
        console.log('logout');
        this.context.authService
            .tryToLogout()
            .then(() => {
                console.log('logout success');
            })
            .catch(error => {
                console.log('logout error');
                console.log(error);
            });
    };

    setLanguage = lang => {
        this.context.translateService.setLanguage(lang);
        console.log(this.context.translateService.getTranslation(appTranslate.nav.catalogue));
    };

    render() {
        return (
            <nav className={'_nav ' + (this.state.navOpen ? '_nav--is-open' : '')}>
                <div className='_nav__left'>
                    <img src={logoImg} className='_nav__left__logo' alt='GE Healthcare logo' />
                </div>
                <div className='_nav__middle'>
                    <Link to='/catalogue' onClick={this.closeNav}>
                        {this.context.translateService.getTranslation(appTranslate.nav.catalogue)}
                    </Link>
                    {this.context.authService.getRoleId() === 1 ? (
                        <Link to='/magazine' onClick={this.closeNav}>
                            {this.context.translateService.getTranslation(appTranslate.nav.magazine)}
                        </Link>
                    ) : null}
                    {this.context.authService.getRoleId() === 1 ? (
                        <Link to='/contracts' onClick={this.closeNav}>
                            {this.context.translateService.getTranslation(appTranslate.nav.contracts)}
                        </Link>
                    ) : null}
                    {this.context.authService.getRoleId() === 1 ? (
                        <Link to='/company' onClick={this.closeNav}>
                            {this.context.translateService.getTranslation(appTranslate.nav.company)}
                        </Link>
                    ) : null}
                    {/* <Link to='/shop' onClick={this.closeNav}>
                        {this.context.translateService.getTranslation(
                            appTranslate.nav.shop
                        )}
                    </Link> */}
                    <button onClick={this.logout}>
                        {this.context.translateService.getTranslation(appTranslate.nav.logout)}
                    </button>

                    <button onClick={() => this.setLanguage('pl')}>PL</button>

                    <button onClick={() => this.setLanguage('en')}>EN</button>
                </div>
                <div className='_nav__right'>
                    <div className='_nav__toggle' onClick={() => this.toggleNav()}>
                        <div className='_nav__toggle__bar' />
                    </div>
                </div>
            </nav>
        );
    }
}
Nav.contextType = AppContext;

export default Nav;
