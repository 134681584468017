import React, { Component } from 'react';

import './FieldWrapper.scss';

export default class FieldWrapper extends Component {
    render() {
        let classes = [
            'field-wrapper',
            this.props.error && this.props.error.length > 0
                ? 'field-wrapper--error'
                : ''
        ];

        return (
            <div className={classes.join(' ')}>
                <div className='field-wrapper__label'>{this.props.label}</div>
                <div className='field-wrapper__field'>
                    {this.props.children}
                </div>
                <div className='field-wrapper__error'>{this.props.error}</div>
            </div>
        );
    }
}
