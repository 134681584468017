import React, { Component } from 'react';

import './SelectWithButton.scss';

class SelectWithButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption:
                this.props.options.length > 0
                    ? this.props.options[0].value
                    : null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.options.length !== this.props.options.length) {
            this.setState({
                selectedOption:
                    this.props.options.length > 0
                        ? this.props.options[0].value
                        : null
            });
        }
    }

    onChange = value => {
        this.setState(prevState => {
            return {
                selectedOption: value
            };
        });
    };

    onClick = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.selectedOption);
        }
    };

    render() {
        if (this.props.options.length < 1) {
            return <div />;
        }

        return (
            <div className='select-with-button'>
                <select
                    className='select-with-button__select'
                    value={this.state.selectedOption}
                    onChange={event => this.onChange(event.target.value)}
                >
                    {this.props.options.map(o => {
                        return (
                            <option key={o.value} value={o.value}>
                                {o.label}
                            </option>
                        );
                    })}
                </select>
                <div
                    className='select-with-button__button'
                    onClick={() => this.onClick()}
                >
                    {this.props.buttonLabel ? this.props.buttonLabel : ''}
                </div>
            </div>
        );
    }
}

export default SelectWithButton;
