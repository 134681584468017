import React, { Component } from 'react';

import './CheckBox.scss';

class CheckBox extends Component {
    render() {
        return (
            <div
                className='_checkbox'
                onClick={() => this.props.onInput(!this.props.value)}
            >
                <div
                    className={
                        '_checkbox__box ' +
                        (this.props.value ? '_checkbox__box--is-checked' : '')
                    }
                >
                    <i className='material-icons'>done</i>
                </div>
                <div className='_checkbox__label'>{this.props.label}</div>
            </div>
        );
    }
}

export default CheckBox;
