import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

class CatalogueSecondaryNav extends Component {
    render() {
        const match = this.props.parentProps.match;

        return (
            <nav className='nav-secondary'>
                <Link to={`${match.path}/contract`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.contracts.secondaryNav.contracts
                    )}
                </Link>

                {/* <Link to={`${match.path}/company`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.contracts.secondaryNav.company
                    )}
                </Link> */}

                <Link to={`${match.path}/order`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.contracts.secondaryNav.order
                    )}
                </Link>

                <Link to={`${match.path}/request`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.contracts.secondaryNav.requests
                    )}
                </Link>
            </nav>
        );
    }
}
CatalogueSecondaryNav.contextType = AppContext;

export default CatalogueSecondaryNav;
