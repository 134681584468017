import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';
import BaseComponent from '../../../components/baseComponent/BasicFormComponent.js';

class SecondaryNav extends BaseComponent {
    TObject = appTranslate.areas.company.secondaryNav;
    

    render() {
        const match = this.props.parentProps.match;

        return (
            <nav className='nav-secondary'>
                <Link to={`${match.path}/company`}>
                    {this.T('company')}
                </Link>

                {/* <Link to={`${match.path}/employee`}>
                    {this.T('employee')}
                </Link> */}
            </nav>
        );
    }
}
SecondaryNav.contextType = AppContext;

export default SecondaryNav;
