import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import SecondaryNav from './components/SecondaryNav';

import CompanyMain from './views/company/Main';

class Main extends Component {
    render() {
        return (
            <div className='area'>
                <div className='area-wrapper'>
                    <SecondaryNav parentProps={this.props} />
                    <Switch>
                        <Route path={`${this.props.match.path}/company`} component={CompanyMain} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Main;
