import React, { Component } from 'react';

import './Modal.scss';

class Modal extends Component {
    render() {
        if (this.props.visible) {
            return (
                <div className='modal'>
                    <div className='modal__background' />
                    <div className='modal__container'>
                        <div className='modal__header'>
                            <div className='modal__title'>
                                {this.props.title}
                            </div>
                            <div className='modal__close'>
                                <button
                                    className='modal__close-button'
                                    onClick={() => this.props.close(false)}
                                >
                                    <i className='material-icons modal__close-button-icon'>
                                        close
                                    </i>
                                </button>
                            </div>
                        </div>
                        <div className='modal__content'>
                            {this.props.content}
                        </div>
                        <div className='modal__footer'>{this.props.footer}</div>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

export default Modal;
