import React, { Component } from 'react';
import { AppContext } from '../services/context';

import TextField from '../components/form/TextField';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: { value: '' },
            password: { value: '' },
            error: ''
        };
    }

    tryToLogin() {
        if (this.state.login.value.length > 0 && this.state.password.value.length > 0) {
            this.context.authService.tryToLogin(this.state.login.value, this.state.password.value).catch(error => {
                this.setState({ error: error });
            });
        } else {
            console.log('login or password too short');
        }
    }

    render() {
        return (
            <div className='_page'>
                <div className='_modal _modal--small'>
                    <TextField
                        field={this.state.login}
                        handleChange={value => this.setState({ login: { value: value } })}
                        label='Login'
                        type='text'
                        fieldName='login'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <TextField
                        field={this.state.password}
                        handleChange={value => this.setState({ password: { value: value } })}
                        label='Hasło'
                        type='password'
                        fieldName='password'
                        onFocus={() => {}}
                        onBlur={() => {}}
                    />
                    <button className='_button-light' onClick={() => this.tryToLogin()}>
                        Zaloguj
                    </button>
                    <div>{this.state.error}</div>
                </div>
            </div>
        );
    }
}
Login.contextType = AppContext;

export default Login;
