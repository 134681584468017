import { Component } from 'react';
import { AppContext } from '../../services/context';

class BaseComponent extends Component {
    TObject = null;

    constructor(props) {
        super(props);

        this.state = {
            loaders: {
                main: 0
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, this.TObject);
    };

    setLoader = (state, loader) => {
        if (!loader) {
            loader = 'main';
        }
        this.setState(prev => {
            return {
                [loader]: prev.loaders[loader] + (state ? 1 : -1)
            };
        });
    };
}
BaseComponent.contextType = AppContext;
export default BaseComponent;
