import React from 'react';

import { AppContext } from '../../services/context';
import { FieldBase } from './FieldBase';

class TextAreaL extends FieldBase {
    onDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    };

    render() {
        let deleteButton = <div className='text-field__delete-button' />;

        if (this.props.isDeletable) {
            deleteButton = (
                <div className='text-field__delete-button' onClick={() => this.onDelete()}>
                    <i className='material-icons'>close</i>
                </div>
            );
        }

        return (
            <div className={'text-field-l ' + (this.props.field.error && this.props.field.error.length > 0 ? 'text-field-l--is-error' : '')}>
                <div className='text-field__label-button'>
                    <div className='text-field__label'>{this.props.label ? this.props.label : ''}</div>
                    {deleteButton}
                </div>
                {this.context.translateService.languages.map(l => {
                    return (
                        <div className='text-field-l__row' key={l}>
                            <div className='text-field-l__lang'>{l}</div>
                            <textarea
                                className='text-field-l__textarea'
                                value={this.props.field[l]}
                                type={this.props.type}
                                name={`${this.props.fieldName}-${l}`}
                                onChange={e => this.onInput(e.target.value, l)}
                                onBlur={() => this.onBlur(l)}
                                onFocus={() => this.onFocus(l)}
                            />
                        </div>
                    );
                })}

                <div className='text-field-l__error'>{this.props.field.error ? this.props.field.error : ''}</div>
            </div>
        );
    }
}
TextAreaL.contextType = AppContext;

export default TextAreaL;
