import { Languages } from '../Languages';
import moment from 'moment';

export function checkField(field, checks) {
    let errors = [];
    checks.forEach(c => {
        errors = [...errors, ...c(field)];
    });
    return errors;
}

export function checkNotEmptyText(field) {
    if (field.value && field.value.length > 0) {
        return [];
    }
    return [
        {
            pl: 'Pole nie może być puste',
            en: 'Field cannot be empty'
        }
    ];
}

export function checkValidDate(field) {
    let e = [];
    if (!field.value) {
        e.push({ pl: 'Pole nie może być puste', en: 'Field cannot be empty' });
    }
    if (!moment(field).isValid()) {
        e.push({ pl: 'Błędny format daty', en: 'Wrong date format' });
    }
    return e;
}

export function checkNotEmptyTextL(field) {
    let errors = [];
    Languages.forEach(l => {
        if (!field[l] || field[l].length < 1) {
            errors.push({
                pl: `Pole '${l}' nie może być puste`,
                en: `Field '${l}' cannot be empty`
            });
        }
    });
    return errors;
}
