import { Component } from 'react';
import { AppContext } from '../../services/context';

import { checkField } from '../../helpers/validator/Validator';
import { Languages } from '../../helpers/Languages';

class BasicFormComponent extends Component {
    TObject = null;

    constructor(props) {
        super(props);

        this.test = 'test';
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            this.TObject
        );
    };

    setLoader = state => {
        this.setState(() => {
            return {
                loader: state
            };
        });
    };

    /**
     * Validate field
     */
    validateField = field => {

        if(!this.state.form[field] || !this.state.form[field].checks || this.state.form[field].checks.length === 0){
            return 0;
        }

        const errors = checkField(
            this.state.form[field],
            this.state.form[field].checks
        );

        const error = errors.reduce((p, e) => {
            return p + ' ' + this.T(e) + '.';
        }, '');

        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [field]: {
                        ...prevState.form[field],
                        error: error
                    }
                }
            };
        });

        return errors.length;
    };

    /**
     * Validate all fields
     */
    validateFields = () => {
        let errors = 0;
        Object.keys(this.state.form).forEach(f => {
            if (
                this.state.form[f].checks &&
                this.state.form[f].checks.length > 0
            ) {
                errors += this.validateField(f);
            }
        });
        return errors === 0;
    };

    /**
     * Returns language values for passed field
     */
    getFieldL = field => {
        return Languages.reduce((p, l) => {
            return {
                ...p,
                [l]: field[l] ? field[l] : ''
            };
        }, {});
    };

    onInputValueL = (field, l, value) => {
        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [field]: {
                        ...prevState.form[field],
                        [l]: value
                    }
                }
            };
        });
    };

    onInputValue = (field, value) => {
        this.onInputValueL(field, 'value', value);
    };
}
BasicFormComponent.contextType = AppContext;
export default BasicFormComponent;
