import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Nav from '../components/basic/Nav';

import CatalogueMain from '../areas/catalogue/Main';
import MagazineMain from '../areas/magazine/Main';
import ContractsMain from '../areas/contracts/Main';
import ShopMain from '../areas/shop/Main';
import CompanyMain from '../areas/company/Main';

class Main extends Component {
    render() {
        return (
            <div className='app'>
                <Nav />
                <Switch>
                    <Route path='/catalogue' component={CatalogueMain} />
                    <Route path='/magazine' component={MagazineMain} />
                    <Route path='/contracts' component={ContractsMain} />
                    <Route path='/shop' component={ShopMain} />
                    <Route path='/company' component={CompanyMain} />
                </Switch>
            </div>
        );
    }
}

export default Main;
